import TestUnit from './Units/TestUnit.js';
import CircleUnit from './Units/CircleUnit.js';
import LineUnit from './Units/LineUnit.js'


let map = { 
    "test-unit": TestUnit,
    "circle-unit": CircleUnit,
    "line-unit": LineUnit

};

export { map };