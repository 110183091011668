import { Unit } from 'Unit';

class TestUnit extends Unit {
    constructor(options) {
        super(options);

        this.span = this.element.querySelector('[data-unit-span]') || null;
    }

    onScroll(y) {
        super.onScroll(y);

        if (this.span) {
            this.span.innerText = 'SCROLL ' + y + '   positiontop ' + this.positionTop + '   progress ' + this.progress;
        }

        let percent = Math.floor((this.progress * 10000)) / 100;

        this.span.style.transform = 'translate3d('+ (percent - 100) +'%, ' + (percent - 100) + '%, 0)';

    }


    print() {
        super.print();
        console.log('Test Unit');
    }
}

export default TestUnit;