import { Unit } from 'Unit';
import { Canvas } from 'Unit';

import SimplexNoise from 'simplex-noise';

const LINEWIDTH = 1;
const ALPHA_DECAY = .5;

const LINE_FACTOR = 85;
const SEGMENT_FACTOR = 22;
const X_FACTOR = 50;
const Y_FACTOR = 20;
const SIZE_FACTOR = 400;


const FLOOR = Math.floor;
const MAX = Math.max;
const MIN = Math.min;
const SIN = Math.sin;
const SIMPLEX = new SimplexNoise();

let map = function(x, in_min, in_max, out_min, out_max) {
    return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};

class Line {
    constructor(ctx, vOffset, xZero, yZero, originalSize, segments, xVariance, yVariance, lineDistance) {
        this.ctx = ctx;
        this.vOffset = vOffset; 
        this.xZero = xZero;
        this.yZero = yZero;
        this.segments = segments;
        this.segmentSize = originalSize / segments;
        this.xVariance = xVariance;
        this.yVariance = yVariance;
        this.lineDistance = lineDistance;
    }

    draw(t) {

        let myX = this.xZero; 
        let myY = this.yZero + this.vOffset;

        let yFactor = myY;

        this.ctx.beginPath();

        this.ctx.moveTo(myX, myY);

        let first = true;

        let alpha = 1;

        for (let i = 1; i < (this.segments + 1); i ++) {
            myX = myX +  this.segmentSize;

            let redNoise = SIMPLEX.noise3D(myX, myY, t);
            let red = FLOOR(map(redNoise, -1, 1, 0, 190 ));

            let greenNoise = SIMPLEX.noise2D(myY, t);
            let green = FLOOR(map(greenNoise, -1, 1, 0, 127 ));

            let blueNoise = SIMPLEX.noise2D(myX, t);
            let blue = FLOOR(map(blueNoise, -1, 1, 0, 255 ));

            let noiseX = SIMPLEX.noise3D(myX, myY, t/2) * this.xVariance;
            let noiseY = SIMPLEX.noise3D(myX, myY, t) * (this.lineDistance);

            let strokeStyle = `rgba( ${red} , ${green} , ${blue} , ${alpha})`;

            this.ctx.lineTo(myX + noiseX, myY + noiseY);
            this.ctx.strokeStyle = strokeStyle;    
            this.ctx.stroke();

        }

    }

}

class LineUnit extends Unit {
    constructor(options) {
        super(options);

        this.drawing = false;
        this.start = Date.now();
        this.draw = this.unboundDraw.bind(this);
        this.numberOfLines = 0;
        this.numberOfSegments = 0;
        this.originalSize = 0;
        this.xVariance = 0;
        this.yVariance = 0;
        
        this.createCanvas();
        this.createLines();
        this.draw();

    }

    createCanvas() {
        this.numberOfLines = FLOOR(this.height / LINE_FACTOR);
        this.numberOfSegments = FLOOR(this.width / SEGMENT_FACTOR);

        let max = MAX(this.height, this.width);
        this.originalSize = (FLOOR(max / SIZE_FACTOR) + 2) * 100;

        this.xVariance = FLOOR(this.width / X_FACTOR);
        this.yVariance = FLOOR(this.height / Y_FACTOR);

        this.originalC = new Canvas(this.originalSize, this.originalSize, 1, false);

        this.visibleC = new Canvas(this.width, this.height, 1, false);
        this.visibleC.attachTo(this.element);
    }

    createLines() {
        this.lineDistance = this.originalSize / this.numberOfLines;

        this.lines = [];

        for(let i = 0; (i < this.numberOfLines +1); i++) {
            let myLine = new Line(this.originalC.ctx, i * this.lineDistance, 0, 0, this.originalSize, this.numberOfSegments, this.xVariance, this.yVariance, this.lineDistance);
            this.lines.push(myLine);
        }
    }

    unboundDraw() {
        this.drawing = true;
        let now = Date.now();

        let t = (now - this.start) / 10000;

        this.originalC.decayAlpha(ALPHA_DECAY);

        let ctx = this.originalC.ctx;
        ctx.lineWidth = LINEWIDTH;

        for ( var line of this.lines) {
            line.draw(t);
        }

        this.visibleC.clear();
        this.visibleC.draw(this.originalC, 0, 0, this.width, this.height);

        window.requestAnimationFrame(this.draw);
    }

    onScroll(y) {
        super.onScroll(y);

    }

    onResize() {
        super.onResize();

        this.drawing = false;
        this.visibleC.removeFromDom();

        this.createCanvas();
        this.createLines();
        this.draw();
    }

    print() {
        super.print();
    }
}

export default LineUnit;