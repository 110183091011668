
import '../scss/index.scss';

import { Page } from 'Unit';
import { map } from './Patterns/map.js';

let main = function() {
    let myMain = document.querySelector('main');
    let myPage = new Page(myMain, map);
};

main();