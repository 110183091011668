import { Unit } from 'Unit';
import { Canvas } from 'Unit';

const COS = Math.cos;
const SIN = Math.sin;
const FLOOR = Math.floor;

const T = Math.PI / 2;
const TAU = Math.PI * 2;


const SMALL_CANVAS_SIZE = 96 ;
const CIRCLE_RADIUS = SMALL_CANVAS_SIZE / 4;
const MOVEMENT_RADIUS = CIRCLE_RADIUS;

let map = function(x, in_min, in_max, out_min, out_max) {
    return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
};

class Circle {
    constructor(row, column, totalColumns, totalRows, options) {
        this.row = row;
        this.column = column;
        this.totalColumns = totalColumns;
        this.totalRows = totalRows;

        this.width = options.width || 0;
        this.height = options.height || 0;

        this.canvasObj = new Canvas(this.width, this.height, options.zoom || 1, options.smoothing || true);
    }

    draw(time, canvas, positionx, offsetX, positiony, offsetY) {

        let ctx = canvas.ctx;

        let tx = time + (this.column / this.totalColumns) + this.row;
        let ty = time + (this.column / this.totalColumns) + this.row;

        let x = MOVEMENT_RADIUS * COS(tx) + (MOVEMENT_RADIUS * 2);
        let y = MOVEMENT_RADIUS * COS(ty - T) + (MOVEMENT_RADIUS * 2);

        let redMax = (SMALL_CANVAS_SIZE / 2) * (this.totalRows - 1);
        let mappedRed = FLOOR(map(positiony, 0, redMax, 255, 127));

        let blueMax = (SMALL_CANVAS_SIZE / 2) * (this.totalRows - 1);
        let mappedBlue = FLOOR(map(positionx, 0, blueMax, 255, 127));

        let green = SIN(tx /2)
        let mappedGreen = FLOOR(map(green, -1, 1, 255, 127));

        let alpha = .5;

        ctx.fillStyle = 'rgba(' + mappedRed +','+ mappedGreen +',' + mappedBlue + ',' + alpha +')';       
        ctx.beginPath();
        ctx.ellipse(x + positionx + offsetX, y + positiony + offsetY, CIRCLE_RADIUS - ( (CIRCLE_RADIUS - 4) * SIN(tx)), CIRCLE_RADIUS - ( (CIRCLE_RADIUS-4)  * SIN(tx)), 0, 0, TAU);
        
        ctx.fill();

    }
}

class CircleUnit extends Unit {
    constructor(options) {
        super(options);

        this.createCanvas();

        this.boundDraw = this.draw.bind(this);

        this.playing = false;
        this.start = 0;

        this.play();

    }

    createCanvas() {
        this.rows = FLOOR(this.height / (SMALL_CANVAS_SIZE / 2)) + 2;
        this.columns = FLOOR(this.width / (SMALL_CANVAS_SIZE / 2)) + 2;

        this.createCircles();
        this.visibleC = new Canvas(this.width, this.height, 1, false);

        this.visibleC.attachTo(this.element);
    }

    createCircles() {
        this.circles = [];

        let options = {};
        options.width = SMALL_CANVAS_SIZE;
        options.height = SMALL_CANVAS_SIZE;
        options.zoom =  1;
        options.smoothing =  true;

        for ( let row = 0; row < this.rows; row++ ) {
            for ( let col = 0; col < this.columns; col++) {
                let circle =  new Circle(row, col, this.columns, this.rows, options);
                this.circles.push(circle);
            }
        }

    }

    draw() {

        let now = Date.now();
        let t = (now - this.start) / 1000;

        this.visibleC.clear();

        let offsetX = (this.width/2) - ((this.columns * (SMALL_CANVAS_SIZE / 2) / 2 ));

        let offsetY = (this.height/2) - ((this.rows * (SMALL_CANVAS_SIZE / 2) / 2 ));

        for (let n = 0; n < this.columns; n++) {
            for (let m = 0; m < this.rows; m++) {
                let index = (n * 4) +  m;
                let myCircle = this.circles[index]; 
                myCircle.draw(t, this.visibleC, (n* (SMALL_CANVAS_SIZE / 2) ), offsetX , (m* (SMALL_CANVAS_SIZE / 2) ), offsetY);
            }
        }

        if (this.playing) {
            window.requestAnimationFrame(this.boundDraw);
        }

    }

    play() {
        this.playing = true;
        this.start = Date.now();
        this.boundDraw();
    }

    onResize() {
        super.onResize();

        this.playing = false;
        this.visibleC.removeFromDom();

        this.createCanvas();

        this.play();
    }


    onScroll(y) {
        super.onScroll(y);
    }


    print() {
        super.print();
    }
}

export default CircleUnit;